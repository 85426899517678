import React, { useEffect, useState } from 'react'
import { CssBaseline } from '@material-ui/core'
import { GlobalStyle } from './RootLayout.styled'
import { LayoutProps } from '../../ui/routes'
import { NotistackProvider } from '@/app/ui-new/components/Notistack/Notistack'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import { newDesignThemes } from '@/app/ui/theme'
import NotistackHandler from '@/app/ui-new/layouts/NotistackHandler'
import NotistackAlerts from '@/app/ui-new/layouts/NotistackAlerts'
import Cookies from 'js-cookie'
import { configNotificationCookiesMessage } from './components/NotistackAlert/lib/helpers'

const RootLayout: React.FunctionComponent<Props> = ({ children }) => {
    const cookiesSnakbarOpen = Cookies.get('cookie-law-agreed') === undefined

   // const [messageSnakbarOpen, setMessageSnakbarOpen] = useState(false)
    //MESSAGE ONCE A DAY:
    // const cookieName = 'messageStrike'
    const messageSnakbarOpen = configNotificationCookiesMessage('messageStrike')

    // useEffect(() => {
       
    //     console.log('lastShownDate',lastShownDate,'currentDate',currentDate )
    //     if (lastShownDate !== currentDate) {
    //         // Show the message
    //         console.log('image here')
    //         setMessageSnakbarOpen(true)
    //     }
    // }, [])

    const checkPage = () =>{
        const currentUrl = window.location.pathname; // Get the path part of the URL
        const pathSegments = currentUrl.split('/').filter(Boolean); // Split by '/' and remove empty segments
        
        // Check if the first folder is either 'orders' or 'marketplace'
        return pathSegments[0] === 'orders' || pathSegments[0] === 'marketplace';
    } 

    return (
        <>
            <CssBaseline />
            <GlobalStyle />
            <MuiThemeProvider theme={newDesignThemes[0]}>
                <ThemeProvider theme={newDesignThemes[0]}>
                    <NotistackProvider autoHideDuration={6000}>
                        <NotistackHandler>
                            <NotistackAlerts versionSnackbarOpen={false} cookiesSnakbarOpen={cookiesSnakbarOpen} />
{/*                           Remeve this comment to add message to the client \\ To do: future, lets create a system to add message */}
                            {/* <NotistackAlerts messageUserSnackbar ={{open: messageSnakbarOpen, cookieName:cookieName, expiresDays: 1}} /> */}
                            {children}
                        </NotistackHandler>
                    </NotistackProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </>
    )
}

export interface Props extends LayoutProps {}

export default RootLayout
