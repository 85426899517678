import React, { useEffect, useState } from 'react'
import { FormControl, FormHelperText, Grid, ListItem, MenuItem, Theme, useTheme } from '@material-ui/core'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import CarrierLogo from '../../CarrierLogo/CarrierLogo'
import { Typography, InputLabel, ValueTypography, Select, RelativeContainer, Icon, SecondaryButton, ExtraInfoGrid } from './OutlinedSelectForm.styled'
import { faCalculator, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons-lazr-alias'
import TextWithTooltip from '../../TextWithTooltip/TextWithTooltip'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { AppTheme } from '@/app/ui/definitions/NewDesignTheme'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface InputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => any
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: (value: any) => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
    force?: boolean
}

const OutlinedSelectForm: React.FC<InputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    force,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {
    const { labelName, required, size, isFocus: isDefaultFocus, layoutConfig, select, forceStop } = field
    const theme = useTheme<AppTheme & Theme>()
    const [isDirty, setIsDirty] = useState(false)
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(field.name, field.values ?? field.defaultValue)
    }, [field.values])
    return (
        <>
            <Controller
                name={field.name}
                control={control}
                defaultValue={field.defaultValue}
                render={({ field: { onChange, value, name } }) => {
                    const errorField = error ? error : errors?.[name]                
                    const isEmpty = required && !getValues(field.name)
                    const hasError = (isDirty && isEmpty) || (force && !!errorField)
                    return <RelativeContainer>
                        <FormControl fullWidth variant="outlined" size={size}>
                            <InputLabel $backgroundColor={layoutConfig?.labelColor} $size={size}>
                                <>
                                    <Typography
                                        $color={hasError ? theme.palette.error900.main : 'black'}
                                        $fontSize={16}
                                        $fontWeight={600}
                                        ml={1}
                                    >
                                        {labelName}
                                    </Typography>
                                    {field.required && !field.disabled && <Typography
                                        ml={1}
                                        $fontSize={18}
                                        $fontWeight={600}
                                        $color={hasError ? theme.palette.error900.main : 'black'}
                                    >*</Typography>}
                                </>
                            </InputLabel>
                            <Select
                                size={size}
                                disabled={field.disabled}
                                error={!!hasError}
                                id={`outline-select-${field.name}`}
                                labelId={`outline-select-${field.name}-label`}
                                value={value ?? (field.defaultValue ?? 'None')}
                                onChange={(e, newValue: any) => {
                                    const val = forceStop ? value : newValue?.props?.value
                                    setIsDirty(true)
                                    onChange(val)
                                    setValue(field.name, val, { shouldValidate: false })
                                    // Trigger validation on change
                                    trigger(field.name).then(() => {
                                        const isFieldValid = !errors[field.name]
                                        if (isFieldValid && onChange) {
                                            onChange(val)
                                            setValue(field.name, val, {
                                                shouldValidate: true,
                                            })
                                        }
                                        debouncedHandleOnChange?.({[field.name]: val})
                                    })
                                }}
                                renderValue={(value) => {
                                    const text = value !== 'None' ? 
                                    field.select?.data.find((data: {text: string, value: any}) => data.value === value)?.text ?? '-' : 
                                    '-'
                                    return <ValueTypography $size={size}>{text}</ValueTypography>
                                }}
                            >
                                {
                                    field.select?.data?.map((item: {text: string, value: any}) => {
                                        return (
                                            <MenuItem value={item.value} key={item.text}>
                                                {item.text}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {field.select?.extraInfo && <ExtraInfoGrid mt={1}>
                                    {field.select.extraInfo}
                                </ExtraInfoGrid>}
                        </FormControl>
                    </RelativeContainer>
                }}
            />
        </>
    )
}

export default OutlinedSelectForm
