import React, { useEffect, useState, forwardRef } from 'react'
import parse from 'autosuggest-highlight/parse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputAdornment } from '@material-ui/core'
import { Autocomplete, AutocompleteChangeReason, AutocompleteRenderOptionState } from '@material-ui/lab'
import {
    Grid,
    IconContainer,
    CheckContainer,
    CustomListTypography,
    TextField,
    SecondaryTypography,
    IconContainerArrow,
    GroupTypography,
    BackTypography,
    IconContainerLeftArrow,
    IconContainerTextField,
    IconContainerAddressMenu,
    AutoCompleteContainer,
    IconButton,
    ListBoxGrid,
    MenuItemGrid,
    UlCustom,
} from './AddressSearchBar.styled'
import { deepCopy } from '@lazr/utilities'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './AddressSearchBar.i18n'
import { AddressAccessorial } from '@/app/model'
import { CollectAccount } from '@/app/model/Address'
import DefaultShippingAddressPicker from '@/app/ui-new/pages/marketplace/components/DefaultShippingAddressPicker/DefaultShippingAddressPicker'
import {
    PlacesAutocompleteResult
} from '@/app/ui-new/pages/marketplace/OriginAndDestination/OriginAndDestination.module/components/AddressInformation/Components/PlacesAutocomplete/PlacesAutocomplete'
import Tooltip from '@/app/ui-new/components/Tooltip/Tooltip'

const isAddressSearchOption = (request: AddressSearchOption | CustomListElement): request is AddressSearchOption =>
    (request as AddressSearchOption).type !== undefined

const inputAdornment = (currentValue: AddressSearchOption | CustomListElement | null, showMapMarker?: boolean) => {
    if (showMapMarker) {
        return <FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]} />
    }
    if (!currentValue || !isAddressSearchOption(currentValue)) {
        return <FontAwesomeIcon icon={[ 'far', 'search' ]} />
    }

    if (currentValue.isAddressBook) {
        return <FontAwesomeIcon icon={[ 'fas', 'address-card' ]} />
    }

    return <FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]} />
}

const AddressSearchBar: React.FunctionComponent<Props> = ({
    id,
    helperText,
    variant,
    required,
    disabled,
    autoFocus,
    options,
    getOptionLabel,
    filterOptions,
    value,
    onChange,
    textFieldOnchange,
    className,
    rightButton,
    appendToList,
    size,
    error,
    showLegend,
    fixedInputValue,
    openAddressBook,
    ...rest
}) => {
    const { t } = useI18n(i18n)

    const [ currentOptions, setCurrentOptions ] = useState<(AddressSearchOption | CustomListElement)[]>(options)
    const [ currentValue, setCurrentValue ] = useState<AddressSearchOption | CustomListElement | null>(value)
    const [ open, setOpen ] = useState<boolean>(false)
    const [ menuArray, setMenuArray ] = useState<MenuItems[]>([])
    const [ showBackMenu, setShowBackMenu ] = useState<boolean>(false)
    const [ input, setInput ] = useState<string>('')
    const [ focusInListBox, setFocusInListBox ] = React.useState(true)
    const [ mouseOverBackButton, setMouseOverBackButton ] = React.useState(false)
    const [ focusOnBackButton, setFocusOnBackButton ] = React.useState(false)

    useEffect(() => {
        setCurrentOptions(options)
        setCurrentValue(value)
        if (menuArray.length === 0) {
            setShowBackMenu(false)
        }
        if (menuArray.length === 1) {
            setShowBackMenu(true)
        }
        setFocusInListBox(true)
        setFocusOnBackButton(false)
        setMouseOverBackButton(false)
    }, [ options ])

    const handleClose = (event: object, reason: string) => {
        if (reason !== 'select-option') {
            setOpen(false)
            setMenuArray([])
            setShowBackMenu(false)
            setMouseOverBackButton(false)
            setFocusOnBackButton(false)
        }
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleBackClick = () => {
        const newMenuArray: MenuItems[] = deepCopy(menuArray)
        const lastMenu = newMenuArray.pop()
        const event = new Event('change')
        setOpen(true)
        onChange(event, lastMenu?.value ?? null, 'select-option')
        setMenuArray(newMenuArray)
    }

    const handleTextFieldKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const eventTarget = event?.target as HTMLDivElement
        let currentFocusOnBackButton = focusOnBackButton
        const activedescendant = eventTarget?.getAttribute('aria-activedescendant')

        if (showBackMenu && event.key === 'ArrowUp' && activedescendant === `${id}-option-0`) {
            currentFocusOnBackButton = true
        }
        else if (showBackMenu && event.key === 'ArrowUp' && !activedescendant && focusOnBackButton) {
            event.preventDefault()
            event.stopPropagation()
            currentFocusOnBackButton = false
        }
        else if (showBackMenu && event.key === 'ArrowDown' && !activedescendant) {
            if (!focusOnBackButton) {
                event.preventDefault()
                event.stopPropagation()
            }
            currentFocusOnBackButton = !focusOnBackButton
        }
        else if (showBackMenu && event.key === 'ArrowDown' && activedescendant === `${id}-option-0` && focusOnBackButton) {
            event.preventDefault()
            event.stopPropagation()
            currentFocusOnBackButton = false
        }
        else if (event.key === 'Enter' && (focusOnBackButton || mouseOverBackButton)) {
            event.preventDefault()
            event.stopPropagation()
            currentFocusOnBackButton = false
            handleBackClick()
        }
        else if (event.key === 'Enter' && !focusInListBox) {
            event.preventDefault()
            event.stopPropagation()
            if (!showBackMenu) {
                setOpen(false)
                setInput(currentValue ? currentValue.description : '')
            }
        }
        else if (focusOnBackButton){
            currentFocusOnBackButton = false
        }

        if (event.key === 'Home' || event.key === 'End') {
            setFocusInListBox(true)
            currentFocusOnBackButton = false
        }

        setFocusOnBackButton(currentFocusOnBackButton)
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (!currentFocusOnBackButton) {
                setFocusInListBox(true)
            }
            else {
                setFocusInListBox(false)
            }
        }
    }

    const handleOnChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        newValue: AddressSearchOption | CustomListElement | null,
        reason: AutocompleteChangeReason,
    ) => {
        if (newValue && !isAddressSearchOption(newValue)) {
            event.preventDefault()
            event.stopPropagation()
            if (reason === 'select-option') {
                newValue.onClick()
            }
            setOpen(false)

            return
        }

        if (newValue && newValue.type === SearchAddressType.GROUP && reason === 'select-option') {
            const arrayValue = menuArray.length === 0 && input !== currentValue?.description ? null : currentValue
            if (!arrayValue || isAddressSearchOption(arrayValue)) {
                setMenuArray([ ...menuArray, { value: arrayValue } ])
            }
            setFocusInListBox(false)
        }
        else {
            setOpen(false)
            setMenuArray([])
            setShowBackMenu(false)
        }

        return onChange(event, newValue, reason)
    }

    const ListBoxComponent = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
    >((props: any, ref: any) => {
        const { children, ...other } = props

        return (<div>
            {showBackMenu &&
                <ListBoxGrid container xs={2} item alignItems="center" spacing={0} pl={4} pt={4} pb={1}
                    className={focusOnBackButton ? 'visibleFocus' : ''}
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => handleBackClick()}
                    onMouseEnter={() => setMouseOverBackButton(true)}
                    onMouseLeave={() => setMouseOverBackButton(false)}
                >
                    <Grid item xs={'auto'} ml={4}>
                        <IconContainerLeftArrow>
                            <FontAwesomeIcon icon={[ 'far', 'arrow-left' ]}/>
                        </IconContainerLeftArrow>
                    </Grid>
                    <Grid item xs={'auto'} m={1} ml={2.5}>
                        <BackTypography variant="body1" color="textSecondary">
                            Back
                        </BackTypography>
                    </Grid>
                </ListBoxGrid>
            }
            <UlCustom
                ref={ref}
                role='listbox' {...other}
                $focus={focusInListBox}
            >
                { children }
            </UlCustom>
        </div>
        )
    })
    ListBoxComponent.displayName = 'ListBoxComponent'

    return (
        <React.Fragment>
            {!disabled &&
                <IconContainerAddressMenu size={size}>
                    {rest.showDefaultShippingAddressIcon &&
                        <DefaultShippingAddressPicker
                            onClick={rest.onClickDefaultShippingAddressIcon}
                    
                        />
                    }
                    {openAddressBook && <Tooltip title={t('Address Book')} arrow placement='top'>
                        <IconButton
                            onClick={openAddressBook}
                        >
                            <FontAwesomeIcon icon={['fas', 'address-book']} />
                        </IconButton>
                    </Tooltip>}
                </IconContainerAddressMenu>
            }
            <div id="places-service-div" style={{ display: 'none' }} />
            <AutoCompleteContainer>
                <Autocomplete
                    className={className}
                    disabled={disabled}
                    id={id}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    disableCloseOnSelect
                    value={currentValue}
                    fullWidth
                    clearOnBlur
                    filterSelectedOptions={!!currentValue && isAddressSearchOption(currentValue)
                        && currentValue?.type === SearchAddressType.GROUP}
                    onInputChange={(event, inputValue: string) => {
                        const changeEvent = event as React.ChangeEvent<HTMLInputElement>
                        if (!changeEvent?.currentTarget?.querySelector('.button-list')) {
                            setInput(inputValue)
                        }
                    }}
                    onChange={(event, newValue, reason) => {
                        handleOnChange(event as React.ChangeEvent<HTMLInputElement>, newValue, reason)
                    }}
                    disabledItemsFocusable
                    getOptionLabel={(option: AddressSearchOption | CustomListElement) =>
                        isAddressSearchOption(option) ? getOptionLabel(option) : ''}
                    getOptionSelected={(option, selectValue) => {
                        if (isAddressSearchOption(option) && isAddressSearchOption(selectValue)){
                            return option.description === selectValue.description &&
                                option.addressId === selectValue.addressId && option.id === selectValue.id
                        }

                        return false
                    }}
                    options={currentOptions.concat(appendToList ?? [])}
                    popupIcon={''}
                    inputValue={fixedInputValue ? fixedInputValue : input}
                    filterOptions={(optionList: (AddressSearchOption | CustomListElement)[], state: object) => {
                        const adressSearchOptions: AddressSearchOption[] = optionList.filter((option): option is AddressSearchOption =>
                            isAddressSearchOption(option))
                        const filterAddressSearchOptions = filterOptions(adressSearchOptions, state)
                        const customListElements: CustomListElement[] = optionList.filter((option): option is CustomListElement =>
                            !isAddressSearchOption(option) && option.show)

                        return [ ...filterAddressSearchOptions, ...customListElements ]
                    }}
                    autoComplete
                    ListboxProps={{
                        onMouseEnter: () => setFocusInListBox(true),
                        onMouseLeave: () => setFocusInListBox(false),
                        onMouseMove: () => {
                            if (!focusInListBox) {
                                setFocusInListBox(true)
                            }

                            if (focusOnBackButton) {
                                setFocusOnBackButton(false)
                            }
                        },
                    }}
                    includeInputInList
                    ListboxComponent={ListBoxComponent}
                    renderInput={(params): React.ReactNode => (
                        <TextField
                            {...params}
                            required={required}
                            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                handleTextFieldKeyDown(event)
                            }}
                            placeholder={t('Enter full address, postal code, city or address contact')}
                            InputProps={{
                                ...params.InputProps,
                                autoComplete: 'new-password',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconContainerTextField size={size}>
                                            {inputAdornment(currentValue, !!fixedInputValue)}
                                        </IconContainerTextField>
                                    </InputAdornment>
                                ),
                                endAdornment:(
                                    <InputAdornment position="end">
                                        {rightButton ?? ''}
                                    </InputAdornment>
                                ),
                            }}
                            autoFocus={autoFocus}
                            label={''}
                            size={size === 'large' ? undefined : size}
                            helperText={helperText}
                            variant={variant}
                            onChange={(e) => menuArray.length === 0 && textFieldOnchange(e)}
                            fullWidth
                            error={error}
                        />
                    )}
                    renderOption={(option: AddressSearchOption | CustomListElement, state: AutocompleteRenderOptionState): React.ReactNode => {
                        if (!isAddressSearchOption(option)) {
                            return (
                                <MenuItemGrid container alignItems="center" className={'button-list'}>
                                    <Grid item py={1} pl={5}>
                                        <CustomListTypography>
                                            {option.description}
                                        </CustomListTypography>
                                    </Grid>
                                </MenuItemGrid>
                            )
                        }

                        const matches = option.mainTextMatchedSubstrings || []
                        const parts = parse(
                            option.mainText,
                            matches.map((match: PredictionSubstring) => [ match.offset, match.offset + match.length ]),
                        ) || []

                        return (
                            <MenuItemGrid container alignItems="center">
                                <Grid item  pr={1.5}>
                                    <IconContainer >
                                        {!option.isAddressBook ?
                                            <FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]}/> :
                                            <FontAwesomeIcon icon={[ 'fas', 'address-card' ]}/>
                                        }
                                    </IconContainer>
                                </Grid>
                                <Grid item xs m={1}>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>

                                    ))}
                                    {option.secondaryText &&
                                        <SecondaryTypography variant="body2" color="textSecondary">
                                            {option.secondaryText.secondPart ?
                                                `${option.secondaryText.firstPart} · ${option.secondaryText.secondPart}` :
                                                option.secondaryText.firstPart}
                                        </SecondaryTypography>
                                    }
                                </Grid>
                                {state.selected &&
                                    <Grid item ml={0}>
                                        <CheckContainer>
                                            <FontAwesomeIcon icon={[ 'far', 'check-circle' ]}/>
                                        </CheckContainer>
                                    </Grid>
                                }
                                {option.type === SearchAddressType.GROUP &&
                                    <>
                                        <Grid>
                                            <GroupTypography variant="body1">
                                                {`${option?.groupCount ?? ''} Addresses`}
                                            </GroupTypography>
                                        </Grid>
                                        <Grid ml={4}>
                                            <IconContainerArrow>
                                                <FontAwesomeIcon icon={[ 'fas', 'chevron-right' ]} />
                                            </IconContainerArrow>
                                        </Grid>
                                    </>
                                }
                            </MenuItemGrid>
                        )
                    }}
                />
            </AutoCompleteContainer>
            {!!showLegend &&
            <Grid container direction='row' spacing={4} justifyContent={'flex-start'} mb={3} ml={4.5}>
                <Grid item>
                    <Grid container direction='row' spacing={2} justifyContent={'space-between'} alignItems='center' pt={0.75}>
                        <Grid item>
                            {t('Results Type')}:
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' spacing={2} justifyContent={'space-between'} alignItems='center'>
                        <Grid item>
                            <IconContainerTextField size={size}>
                                <FontAwesomeIcon icon={[ 'fas', 'address-card' ]}/>
                            </IconContainerTextField>
                        </Grid>
                        <Grid item>
                            {t('Address Book')}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' spacing={2} justifyContent={'space-between'} alignItems='center'>
                        <Grid item>
                            <IconContainerTextField size={size}>
                                <FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]}/>
                            </IconContainerTextField>
                        </Grid>
                        <Grid item>
                        Google Places
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            }
        </React.Fragment>
    )
}

type MenuItems = {
    value: AddressSearchOption | null
}

interface PredictionSubstring {
    length: number
    offset: number
}

export interface Props extends Omit<
React.ComponentProps<typeof Autocomplete>,
'size' | 'onChange' | 'value' | 'filterOptions' | 'getOptionLabel' | 'renderInput' | 'onSelect'
>{
    id: string
    helperText?: string
    variant?: 'outlined'
    size: 'large'| 'medium' | 'small'
    required?: boolean
    disabled?: boolean
    autoFocus?: boolean
    options: AddressSearchOption[]
    getOptionLabel: (option: AddressSearchOption) => string
    filterOptions: (options: AddressSearchOption[], state: object) => AddressSearchOption[]
    value: AddressSearchOption | null
    onSelect?: (result: PlacesAutocompleteResult) => void
    onChange: (event: object, newValue: AddressSearchOption | null, reason: AutocompleteChangeReason) => void
    textFieldOnchange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    rightButton?: JSX.Element
    appendToList?: CustomListElement[]
    error?: boolean
    showLegend?: boolean
    fixedInputValue?: string
    organizationId?: string
    onClickDefaultShippingAddressIcon?: () => void
    showDefaultShippingAddressIcon?: boolean
    openAddressBook?: () => void
}

export interface AddressSearchOption <T=unknown> {
    id?: string
    isBilling?: boolean
    isBillingDefault?: boolean
    isShipping?: boolean
    isShippingDefault?: boolean
    name?: string
    addressId?: string
    description: string
    isAddressBook: boolean
    mainText: string
    mainTextMatchedSubstrings?: PredictionSubstring[]
    groupCount?: string
    secondaryText?: {
        firstPart: string
        secondPart?: string
    }
    type: SearchAddressType
    data: T
    accessorials?: AddressAccessorial[]
    collectAccounts?: CollectAccount[]
}

export interface CustomListElement {
    show: boolean
    description: string
    onClick: () => void
}

export enum SearchAddressType {
    ADDRESS = 'ADDRESS',
    GROUP = 'GROUP',
}

export default AddressSearchBar

