/* eslint-disable max-len */
// @ts-nocheck
import { deepCopy, isInternationalOrder, isMailingService } from '@lazr/utilities'
import { AddressInformation, MarketPlaceStep, ResultsCompleted } from '@/app/ui-new/definitions/MarketPlace'
import { Address, AddressAccessorial, Country, Search, SearchRfqHandlingUnit } from '@/app/model'
import {
    WeightUnit,
    Currency,
    Provider,
    DocumentType,
    HazmatClass,
    HazmatMode,
    HazmatAccessibiltyType,
    BatteryMaterial,
    BatteryPacking,
    AccessorialLocationType,
    AccessorialType, AddressType,
    TransportType,
    Country as CountryCode
} from '@lazr/openapi-client'
import {
    newMarketplaceAdditionalDetailsRoute,
    newMarketplaceCheckoutRoute,
    newMarketplaceCoverageResultsRoute,
    newMarketplaceCoverageRoute,
    newMarketplaceLoadAndTransitRoute,
    newMarketplaceLoadRoute,
    newMarketplaceLoadTransitCoverageRoute,
    newMarketplaceOriginAndDestinationRoute,
    newMarketplaceResultsRoute,
    newMarketplaceShipmentInfoRoute,
    newMarketplaceStart,
    newMarketplaceThankYouRoute,
} from '@/app/ui/routes'

import { UuidV4 } from '@lazr/types'
import {
    PlacesAutocompleteResult,
} from '@/app/ui-new/pages/marketplace/OriginAndDestination/components/AddressInformation/Components/PlacesAutocomplete/PlacesAutocomplete'
import { AddressDetails, AddressDetailsErrors } from '@/app/ui-new/pages/marketplace/OriginAndDestination'
import { AddressSearchOption, SearchAddressType } from '@/app/ui-new/components/AddressSearchBar/AddressSearchBar'
import { AddressBookType } from '@/app/ui/lib/constants'
import { HoldForPickup, HoldForPickupAddressErrors } from '@/app/ui-new/pages/marketplace/OriginAndDestination'
import { HoldForPickupAddress } from '../../../../model'
import { HandlingUnitData } from '../Load/types'
import { handlingUnitLetters } from '../Load/constants'
import { MarketPlacePattern } from './HandleSteps'

const cleanPath = (path: string) => path.replace(/\/:id\(.*$/, '')

const getCleanPaths = (): Record<MarketPlaceStep, string> => ({
    [MarketPlaceStep.START]: cleanPath(newMarketplaceStart.path),
    [MarketPlaceStep.LOAD_AND_TERMS]: cleanPath(newMarketplaceLoadRoute.path),
    [MarketPlaceStep.ORIGIN_AND_DESTINATION]: cleanPath(newMarketplaceOriginAndDestinationRoute.path),
    [MarketPlaceStep.COVERAGE]: cleanPath(newMarketplaceCoverageRoute.path),
    [MarketPlaceStep.RESULTS]: cleanPath(newMarketplaceResultsRoute.path),
    [MarketPlaceStep.ADDITIONAL_DETAILS]: cleanPath(newMarketplaceAdditionalDetailsRoute.path),
    [MarketPlaceStep.CHECKOUT]: cleanPath(newMarketplaceCheckoutRoute.path),
    [MarketPlaceStep.THANK_YOU]: cleanPath(newMarketplaceThankYouRoute.path),
    [MarketPlaceStep.LOAD_AND_TRANSIT]: cleanPath(newMarketplaceLoadAndTransitRoute.path),
    [MarketPlaceStep.COVERAGE_RESULT]: cleanPath(newMarketplaceCoverageResultsRoute.path),
    [MarketPlaceStep.LOAD_TRANSIT_COVERAGE]: cleanPath(newMarketplaceLoadTransitCoverageRoute.path),
    [MarketPlaceStep.SHIPMENT_INFORMATION]: cleanPath(newMarketplaceShipmentInfoRoute.path),
})

export const getMarketPlaceStepFromPath = (path: any): MarketPlaceStep => {
    if (!(typeof path === 'string' || path instanceof String)) {
        throw new Error('Unknown path provided')
    }

    const paths = getCleanPaths()
    const step = Object.keys(paths)
        .map(Number)
        .find((k: MarketPlaceStep) => {
            const handledPath = path.startsWith(paths[k]) && path.replace(paths[k], '');
            return handledPath !== false && (handledPath.startsWith("/") || handledPath.length === 0)
        })
    if (step === undefined) {
        throw new Error('Unknown path provided')
    }

    return step
}

export const getMarketPlaceStepPath = (step: MarketPlaceStep, oldPath?: any): string => {
    if (oldPath !== undefined && !(typeof oldPath === 'string' || oldPath instanceof String)) {
        throw new Error('Invalid oldPath provided')
    }

    const paths = getCleanPaths()
    const found = paths[step]

    if (!found) {
        throw new Error('Unknown step provided')
    }

    if (oldPath) {
        return oldPath.replace(/^(\/[^/]+){3}/, found)
    }

    return found
}

export const computeProgress = (resultsCompleted: ResultsCompleted, providersSearchedTotal: number | null): number => {
    const allValues = Object.values(resultsCompleted)
    const trueValues = allValues.filter((value) => value)

    return trueValues.length ?
        Math.round((trueValues.length / (!providersSearchedTotal ? allValues.length : providersSearchedTotal)) * 100) : 0
}


export const calculateOrderTotalWeightInPounds = (handlingUnits: Partial<SearchRfqHandlingUnit>[]): number => {
    const handleHandlingUnitTotalWeight = (handlingUnit: Partial<SearchRfqHandlingUnit>): number => {
        const getTotalInPound = (weight: number, unit?: WeightUnit | null): number => {
            if (unit === WeightUnit.KG) {
                return weight / 0.453592
            } else {
                return weight
            }
        }
        if (handlingUnit.totalWeight) {
            return getTotalInPound(handlingUnit.totalWeight, handlingUnit.totalWeightUnit)
        } else if (handlingUnit.weight && handlingUnit.quantity) {
            return getTotalInPound(handlingUnit.weight * handlingUnit.quantity, handlingUnit.weightUnit)
        } else {
            return 0
        }
    }
    let totalWeight = 0
    if (handlingUnits && handlingUnits.length) {
        totalWeight = handlingUnits
            .reduce((accumulator, handlingUnit) =>
                handlingUnit?.totalWeight && handlingUnit.totalWeight > 0
                    ? accumulator + handleHandlingUnitTotalWeight(handlingUnit)
                    : accumulator, 0,
            )
    }

    return totalWeight
}

export const calculateOrderBasicCoverage = (
    handlingUnits: Partial<SearchRfqHandlingUnit>[],
    currency: Currency,
    currencyRateCadToUsd?: number,
): number => {
    const basicCoverageInCAD = calculateOrderTotalWeightInPounds(handlingUnits) * 2

    if (currency === Currency.USD) {
        return basicCoverageInCAD * (currencyRateCadToUsd || 1)
    }

    return basicCoverageInCAD
}

export const calculateOrderBasicCoveragePercentage = (order: Partial<Search>): number => {
    const handlingUnits: Partial<SearchRfqHandlingUnit>[] = deepCopy(order.rfq?.handlingUnits)

    const commodityValue = order.rfq?.shipmentCoverageValue || 0

    if (commodityValue) {
        return Math.min(100, Math.round((calculateOrderBasicCoverage(handlingUnits, Currency.CAD) / commodityValue) * 100))
    }

    return 0
}

export const calculateOrderCustomsBrokerFees = (rfqHandlingUnits?: Partial<SearchRfqHandlingUnit>[]): number => {
    const handlingUnits: Partial<SearchRfqHandlingUnit>[] = deepCopy(rfqHandlingUnits)

    const totalInPounds = calculateOrderTotalWeightInPounds(handlingUnits)

    if (totalInPounds > 20) {
        return 15 + (Math.round((totalInPounds - 20) * 0.75))
    } else {
        return 15
    }
}

export type CustomsDocumentsTypeListItem = {
    id: DocumentType
    label: string
}

export const customsDocumentsTypeListItem: CustomsDocumentsTypeListItem[] = [
    {
        id: DocumentType.AUTHORIZATION_FORM,
        label: 'Authorization Form',
    },
    {
        id: DocumentType.CERTIFICATE_OF_ORIGIN,
        label: 'Certificate of Origin',
    },
    {
        id: DocumentType.EXPORT_ACCOMPANYING_DOCUMENT,
        label: 'Export Accompanying Document',
    },
    {
        id: DocumentType.EXPORT_LICENSE,
        label: 'Export License',
    },
    {
        id: DocumentType.IMPORT_PERMIT,
        label: 'Import Permit',
    },
    {
        id: DocumentType.ONE_TIME_NAFTA,
        label: 'One Time NAFTA',
    },
    {
        id: DocumentType.OTHER_CUSTOMS,
        label: 'Other Document',
    },
    {
        id: DocumentType.POWER_OF_ATTORNEY,
        label: 'Power Of Attorney',
    },
    {
        id: DocumentType.PACKING_LIST,
        label: 'Packing List',
    },
    {
        id: DocumentType.SED_DOCUMENT,
        label: 'SED Document',
    },
    {
        id: DocumentType.SHIPPERS_LETTER_OF_INSTRUCTION,
        label: 'Shipper\'s Letter Of Instruction',
    },
    {
        id: DocumentType.DECLARATION,
        label: 'Declaration',
    },
    {
        id: DocumentType.USMCA_CERTIFICATION_OF_ORIGIN,
        label: 'USMCA Certification Of Origin',
    },
    {
        id: DocumentType.USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN,
        label: 'USMCA Commercial Invoice Certification Of Origin',
    },
    {
        id: DocumentType.PRO_FORMA_INVOICE,
        label: 'Pro Forma Invoice',
    },
    {
        id: DocumentType.OTHER_CUSTOMS,
        label: 'Other Customs Document',
    },
]

export const getProviderSpecificUnsupportedEtdDocumentList = (order: Partial<Search>) => {
    if (!isInternationalOrder(order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code)) {
        return undefined
    }

    const provider = order.rfq?.selectedQuote?.provider
    if (!provider) {
        return undefined
    }

    const uploadedDocument = order.documents?.map((document) => document.type) || []
    if (!uploadedDocument.length) {
        return undefined
    }

    // From the uploadedDocument, return the ETD unsupported base on the provider suported list below.
    const supportedCustomsDocumentTypePerProvider: Record<Provider, DocumentType[]> = {
        [Provider.PROJECT_44]: [],
        [Provider.ROSE_ROCKET]: [],
        [Provider.GLS_CANADA]: [],
        [Provider.MANITOULIN]: [],
        [Provider.THREE_PL]: [],
        [Provider.PUROLATOR]: [],
        [Provider.FEDEX]: [
            DocumentType.COMMERCIAL_INVOICE,
        ],
        [Provider.DHL]: [
            DocumentType.INVOICE_SUPPORT,
            DocumentType.PRO_FORMA_INVOICE,
            DocumentType.CERTIFICATE_OF_ORIGIN,
            DocumentType.COMMERCIAL_INVOICE,
            DocumentType.DECLARATION,
            DocumentType.WAYBILL_DOCUMENT,
            DocumentType.USMCA_CERTIFICATION_OF_ORIGIN,
        ],
        [Provider.NATIONEX]: [],
        [Provider.CANPAR]: [],
        [Provider.DAY_AND_ROSS]: [],
        [Provider.CANADA_POST]: [],
        [Provider.UPS]: [
            DocumentType.AUTHORIZATION_FORM,
            DocumentType.COMMERCIAL_INVOICE,
            DocumentType.CERTIFICATE_OF_ORIGIN,
            DocumentType.EXPORT_ACCOMPANYING_DOCUMENT,
            DocumentType.EXPORT_LICENSE,
            DocumentType.IMPORT_PERMIT,
            DocumentType.ONE_TIME_NAFTA,
            DocumentType.POWER_OF_ATTORNEY,
            DocumentType.PACKING_LIST,
            DocumentType.SED_DOCUMENT,
            DocumentType.SHIPPERS_LETTER_OF_INSTRUCTION,
            DocumentType.DECLARATION,
            DocumentType.OTHER_CUSTOMS,
        ],
    }

    const supportedType = supportedCustomsDocumentTypePerProvider[provider as keyof typeof supportedCustomsDocumentTypePerProvider]
    const documentsToDisplay = uploadedDocument.filter((documentType) => !(supportedType).includes(documentType))

    return documentsToDisplay.length ? documentsToDisplay : undefined
}

export const originPickupDateFormat = 'YYYY-MM-DD'

interface SearchRfqAccessorial {
    isRemovable: boolean
    accessorial: SearchAccessorial
    id: UuidV4
}

export interface SearchAccessorial {
    id: UuidV4
    code: string
    name: string
    type: AccessorialType
    transportTypes: TransportType[]
    locationType: AccessorialLocationType
    forceCustomQuote: boolean
    group?: 'Main' | 'Appointment' | 'SpecialHandling'
}


export interface AccessorialCard extends SearchRfqAccessorial {
    selected?: boolean
    isMostPopular?: boolean
}

export const getAddressSummary = (addressDetails: AddressDetails): string => {
    const address: string[] = []
    if (addressDetails.streetAddress2) {
        address.push(addressDetails.streetAddress2)
    }
    if (addressDetails.streetAddress) {
        address.push(addressDetails.streetAddress)
    }
    if (addressDetails.city) {
        address.push(addressDetails.city)
    }
    if (addressDetails.state) {
        address.push(addressDetails.state)
    }
    if (addressDetails.country) {
        address.push(addressDetails.country.name)
    }

    return address.join(', ')
}
export const formatHoldForPickupAddress = (addressInfo: HoldForPickupAddress | null, countryList: Country[]): string => {
    if (!addressInfo) {
        return null
    }
    const addressItems: string[] = []
    const country = countryList.find((item) => item.code === addressInfo.country)

    if (addressInfo.companyName) {
        addressItems.push(addressInfo.companyName)
    }
    if (addressInfo.streetAddress3) {
        addressItems.push(addressInfo.streetAddress3)
    }
    if (addressInfo.streetAddress2) {
        addressItems.push(addressInfo.streetAddress2)
    }

    if (addressInfo.streetAddress) {
        addressItems.push(addressInfo.streetAddress)
    }

    if (addressInfo.city) {
        addressItems.push(addressInfo.city)
    }

    if (addressInfo.state) {
        addressItems.push(addressInfo.state)
    }

    if (addressInfo.country) {
        addressItems.push(country.name)
    }

    if (addressInfo.postalCode) {
        addressItems.push(addressInfo.postalCode)
    }

    return addressItems.join(', ')
}
export const formatAddress = (addressInfo: AddressDetails): string => {
    const addressItems: string[] = []

    if (addressInfo.companyName) {
        addressItems.push(addressInfo.companyName)
    }

    if (addressInfo.streetAddress2) {
        addressItems.push(addressInfo.streetAddress2)
    }

    if (addressInfo.streetAddress) {
        addressItems.push(addressInfo.streetAddress)
    }

    if (addressInfo.city) {
        addressItems.push(addressInfo.city)
    }

    if (addressInfo.state) {
        addressItems.push(addressInfo.state)
    }

    if (addressInfo.country) {
        addressItems.push(addressInfo.country.name)
    }

    if (addressInfo.postalCode) {
        addressItems.push(addressInfo.postalCode)
    }

    if (addressInfo.openTime) {
        addressItems.push(addressInfo.openTime)
    }

    if (addressInfo.closeTime) {
        addressItems.push(addressInfo.closeTime)
    }

    return addressItems.join(', ')
}

export const renderAddress = (address: Address): string =>
    `${address.companyName?address.companyName+', ':''}${address.streetAddressLine1} ${address.city}, ${address.state ? address.state+', ':''}${address.postalCode ? address.postalCode + ', ' : '' }${address.country.name}`


export const validateAddressDetails = (addressDetails: AddressDetails, missingFieldMessage: string, isStateRequired = true): { addressDetailsErrors: AddressDetailsErrors; isValid: boolean } => {
    const addressDetailsErrors: AddressDetailsErrors = {
        state: isStateRequired ? (addressDetails.state ? '' : missingFieldMessage) : '',
        country: addressDetails.country ? '' : missingFieldMessage,
        city: addressDetails.city ? '' : missingFieldMessage,
        streetAddress: addressDetails.streetAddress ? '' : missingFieldMessage,
        postalCode: addressDetails.postalCode ? '' : missingFieldMessage,
    }

    const isValid =
        !!(
            (isStateRequired ? addressDetails.state : true) &&
            addressDetails.country &&
            addressDetails.city &&
            addressDetails.streetAddress &&
            addressDetails.postalCode
        )

    return { isValid, addressDetailsErrors }
}

export const validateHoldForPickup = (holdForPickup: HoldForPickup | null, missingFieldMessage: string): { holdForPickupAddressErrors: HoldForPickupAddressErrors; isValid: boolean } => {
    let errors: HoldForPickupAddressErrors = {
        state: '',
        country: '',
        city: '',
        streetAddress: '',
        postalCode: '',
    }
    let isValid = true
    if (['UPS', 'FEDEX'].includes(holdForPickup?.carrier?.name.toUpperCase() ?? '')) {
        isValid =
            !!(
                holdForPickup?.address?.state &&
                holdForPickup?.address?.country &&
                holdForPickup?.address?.city &&
                holdForPickup?.address?.streetAddress &&
                holdForPickup?.address?.postalCode
            )
        errors = {
            state: holdForPickup?.address?.state ? '' : missingFieldMessage,
            country: holdForPickup?.address?.country ? '' : missingFieldMessage,
            city: holdForPickup?.address?.city ? '' : missingFieldMessage,
            streetAddress: holdForPickup?.address?.streetAddress ? '' : missingFieldMessage,
            postalCode: holdForPickup?.address?.postalCode ? '' : missingFieldMessage,
        }
    }


    return { isValid, holdForPickupAddressErrors: errors }
}

export const getSelectedAccessorialSummary = (acc: AccessorialCard[]): string => {
    const accessorialNames: string[] = []
    acc.forEach((a) => {
        if (a.selected || !a.isRemovable) {
            accessorialNames.push(a.accessorial.name)
        }
    })

    return accessorialNames.join(' \u2022 ')
}

export const updateAccessorialCardToShow = (newAccCards: AccessorialCard[],
    oldAccCards: AccessorialCard[],
    rebuild: boolean): AccessorialCard[] => {
    if (rebuild) {
        return newAccCards
            .filter((acc) => acc.selected)
    } else {
        return oldAccCards.map((acc) => {
            const newCard = newAccCards.find((a) => a.accessorial.code === acc.accessorial.code)
            if (newCard) {
                acc.selected = newCard.selected
            }

            return acc
        })
    }
}

export const getMostPopularAccessorials = (locationType: AccessorialLocationType, transportType: TransportType): string[] => {
    const mostPopularOriginAccessorialsLTL: string[] = ['LGPU', 'APPTPU'/*, 'DOCKPU'*/]
    const mostPopularOriginAccessorialsFTL: string[] = ['APPTPU'/*, 'DOCKPU'*/, 'BAB', 'LUMPER']
    const mostPopularOriginAccessorialsParcel: string[] = ['INDIRSR']
    const mostPopularDestinationAccessorialsLTL: string[] = ['LGDEL', 'APPTDEL'/*, 'DOCKDEL'*/]
    const mostPopularDestinationAccessorialsFTL: string[] = ['APPTDEL'/*, 'DOCKDEL'*/, 'CONDEL', 'LUMPER']
    const mostPopularDestinationAccessorialsParcel: string[] = ['INDIRSR', 'SR']

    if (locationType === AccessorialLocationType.ORIGIN) {
        if (transportType === TransportType.LTL) {
            return mostPopularOriginAccessorialsLTL
        }
        if (transportType === TransportType.FTL) {
            return mostPopularOriginAccessorialsFTL
        }
        if (isMailingService(transportType)) {
            return mostPopularOriginAccessorialsParcel
        }
    }

    if (locationType === AccessorialLocationType.DESTINATION) {
        if (transportType === TransportType.LTL) {
            return mostPopularDestinationAccessorialsLTL
        }
        if (transportType === TransportType.FTL) {
            return mostPopularDestinationAccessorialsFTL
        }
        if (isMailingService(transportType)) {
            return mostPopularDestinationAccessorialsParcel
        }
    }

    return []
}
export const getHoldForPickupAddress = (place: PlacesAutocompleteResult, countryList: Country[]): HoldForPickupAddress => {
    let newStreetAddress = ''

    if (place.streetNumber && place.street) {
        newStreetAddress = `${place.streetNumber.longName} ${place.street.longName}`
    } else if (place.street) {
        newStreetAddress = place.street.longName
    } else if (place.name) {
        newStreetAddress = place.name
    }

    const zipOrPostalCode = place.zipOrPostalCode ? place.zipOrPostalCode.longName : ''
    const country = countryList.find((item) => item.code === place.country?.shortName)

    return {
        id: place.data?.id,
        companyName: place.data?.companyName ?? '',
        streetAddress: newStreetAddress,
        streetAddress2: place.streetAddress2,
        streetAddress3: null,
        city: place.city?.longName ?? '',
        state: place.stateOrProvince?.shortName ?? '',
        country: country.code ?? CountryCode.CA,
        postalCode: zipOrPostalCode,
        phone: place.data?.shippingContactPhone,
        phoneExtension: place.data?.shippingContactPhoneExtension,
    }
}
export const getAddressDetails = (place: PlacesAutocompleteResult, countryList: Country[], lane?: boolean): AddressDetails => {
    let newStreetAddress = ''
    let shortStreetAddress = ''

    if (place.streetNumber && place.street) {
        newStreetAddress = `${place.streetNumber.longName} ${place.street.longName}`
        shortStreetAddress = `${place.streetNumber.shortName} ${place.street.shortName}`
    } else if (place.street) {
        newStreetAddress = place.street.longName
        shortStreetAddress = place.street.shortName
    } else if (place.name) {
        newStreetAddress = place.name
        shortStreetAddress = place.name
    }

    const zipOrPostalCode = place.zipOrPostalCode ? place.zipOrPostalCode.longName : ''
    const country = countryList.find((item) => item.code === place.country?.shortName)

    const companyName = place.data?.companyName ?? (newStreetAddress !== place.name && shortStreetAddress !== place.name ? place.name : '')
    return {
        id: place.data?.id,
        companyName: companyName,
        streetAddress: newStreetAddress,
        streetAddress2: place.streetAddress2,
        streetAddress3: place.streetAddress3,
        city: place.city?.longName ?? '',
        state: place.stateOrProvince?.shortName ?? '',
        country: country ?? null,
        postalCode: zipOrPostalCode,
        addressType: place.addressType,
        detectedAddressType: place.addressType,
        openTime: place.data?.openTime,
        closeTime: place.data?.closeTime,
        contactName: place.data?.shippingContactName,
        contactEmails: place.data?.shippingContactEmails,
        contactPhone: place.data?.shippingContactPhone,
        contactPhoneExtension: place.data?.shippingContactPhoneExtension,
        notify: place.data?.notify,
        instructions: place.data?.instructions,
        accessorials: place.accessorials,
        isBilling: place.data?.isBilling,
        isBillingDefault: place.data?.isBillingDefault,
        isShipping: place.data?.isShipping,
        isShippingDefault: place.data?.isShippingDefault,
        collectAccounts: place.data?.collectAccounts,
    }
}
export const getReduxAutocompleteAddress = (addressInformation: AddressInformation): AddressSearchOption => {
    const formattedAddress = formatAddress(addressInformation)
    if (formattedAddress === '') {
        return null
    }

    return {
        description: formattedAddress,
        isAddressBook: false,
        mainText: formattedAddress,
        type: SearchAddressType.ADDRESS,
        data: addressInformation,
    }
}


export const getOpenCloseTime = (type: AddressBookType, openTime?: string | null, closeTime?: string | null) => {
    switch (type) {
        case AddressBookType.DESTINATION:
            return {
                destinationDeliveryOpenTime: openTime,
                destinationDeliveryCloseTime: closeTime,
            }
        case AddressBookType.ORIGIN:
            return {
                originPickupOpenTime: openTime,
                originPickupCloseTime: closeTime,
            }
        default:
            return {}
    }
}

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
export const toSentenceCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const getAccessorialGroupMapping = (code: string): 'Main' | 'Appointment' | 'SpecialHandling' => {
    switch (code) {
        case 'ASR':
        case 'DSNR':
        case 'SR':
        case 'WEDEL':
        case 'AIRDEL':
        case 'AIRPU':
        case 'BAB':
        case 'DOCKDEL':
        case 'DOCKPU':
        case 'PARKPU':
        case 'FARMPU':
        case 'GOVPU':
        case 'RESDEL':
        case 'RESPU':
            return 'Main'
        case 'APPTDEL':
        case 'APPTPU':
        case 'APPTDELPS':
        case 'APPTPUPS':
        case 'GROPU':
        case 'GRODEL':
            return 'Appointment'
        case 'BLIND':
        case 'CONDEL':
        case 'CONPU':
        case 'CNVDEL':
        case 'CNVPU':
        case 'NOTIFY':
        case 'INDEL':
        case 'INPU':
        case 'LGDEL':
        case 'LGPU':
        case 'LTDDEL':
        case 'LTDPU':
        case 'PFH':
        case 'DRYICE':
        case 'NONCONV':
            return 'SpecialHandling'
        default:
            return 'Main'
    }
}

export const AccessorialNameMapping: Record<string, string> = {
    ASR: 'Adult Signature Required',
    DSNR: 'No Signature Required',
    SR: 'Signature Required',
    WEDEL: 'Weekend Delivery',

    APPTPU: 'Pickup Appointment',
    APPTPUPS: 'Pre-set Pickup Appointment',
    APPTDEL: 'Delivery Appointment',
    APPTDELPS: 'Pre-set Delivery Appointment',
    BLIND: 'Blind Shipment',
    INDEL: 'Inside Delivery',
    INPU: 'Inside Pickup',
    LGDEL: 'Liftgate Delivery',
    LGPU: 'Liftgate Pickup',
    LTDDEL: 'Limited Access Delivery',
    LTDPU: 'Limited Access Pickup',
    RESDEL: 'Residential Delivery',
    RESPU: 'Residential Pickup',
    DOCKDEL: 'Dock Delivery',
    DOCKPU: 'Dock Pickup',
    INDIRSR: 'Indirect Signature Required',
    COMDEL: 'Commercial Delivery',
    COMPU: 'Commercial Pickup',
    DCDEL: 'Distribution Center Delivery',
    PFH: 'Protect From Heat',
    PFZ: 'Protect From Freezing',
    HLDPU: 'Hold For Pickup',
    CALLTAG: 'Call Tag',
    GROPU: 'Grocery Warehouse Pickup',
    GRODEL: 'Grocery Warehouse Delivery',
    DRYICE: 'Dry Ice',
    NONCONV: 'Non Conveyable',
    SATDEL: 'Saturday Delivery',
    SATPU: 'Saturday Pickup',
    HAZM: 'Dangerous Goods',
    BATTERY: 'Lithium Batteries',
    OSNR: 'Origin Signature Not Required',
    EXPDEC: 'Export Declaration',
    EHS: 'Excessive Height',
    ELS_10: 'Excessive Length, 10ft',
    ELS_11: 'Excessive Length, 11ft',
    ELS_12: 'Excessive Length, 12ft',
    ELS_13: 'Excessive Length, 13ft',
    ELS_14: 'Excessive Length, 14ft',
    ELS_15: 'Excessive Length, 15ft',
    ELS_16: 'Excessive Length, 16ft',
    ELS_17: 'Excessive Length, 17ft',
    ELS_18: 'Excessive Length, 18ft',
    ELS_19: 'Excessive Length, 19ft',
    ELS_20: 'Excessive Length, 20ft',
    ELS_21: 'Excessive Length, 21ft',
    ELS_22: 'Excessive Length, 22ft',
    ELS_23: 'Excessive Length, 23ft',
    ELS_24: 'Excessive Length, 24ft',
    ELS_25: 'Excessive Length, 25ft',
    ELS_26: 'Excessive Length, 26ft',
    ELS_27: 'Excessive Length, 27ft',
    ELS_28: 'Excessive Length, 28ft',
    ELS_29: 'Excessive Length, 29ft',
    ELS_30: 'Excessive Length, 30ft and over',
    ELS_8: 'Excessive Length, 8ft',
    ELS_9: 'Excessive Length, 9ft',
}

export const HazmatAccessorialClassMapping: Record<HazmatClass, string> = {
    FULLYREGULATED: 'Fully Regulated',
    UN3373: 'UN3373',
    UN1845: 'UN1845',
    LESSTHAN500KGEXEMPT: 'Less Than 500kg Exempt',
    LIMITEDQUANTITIES: 'Limited Quantities',
}

export const HazmatAccessorialModeMapping: Record<HazmatMode, string> = {
    AIR: 'Air',
    GROUND: 'Ground',
}

export const HazmatAccessorialHazmatAccessibiltyTypeMapping: Record<HazmatAccessibiltyType, string> = {
    ACCESSIBLE: 'Accessible',
    INACCESSIBLE: 'Inaccessible',
}

export const BatteryAccessorialMaterialMapping: Record<BatteryMaterial, string> = {
    LITHIUM_METAL: 'Lithium Metal',
    LITHIUM_ION: 'Lithium Ion',
}
export const BatteryAccessorialPackingMapping: Record<BatteryPacking, string> = {
    //UPS ONLY
    ALONE_IN_BOX: 'Alone In Box',
    PACKED_WITH_EQUIPMENT: 'Packed With Equipment',
    CONTAINED_IN_EQUIPMENT: 'Contained In Equipment'
}
export const AccessorialDescriptionMapping: Record<string, string> = {
    ASR: 'Description for Adult Signature Required',
    DSNR: 'Description for No Signature Required',
    WEDEL: 'Description for Weekend Delivery',
    SR: 'This delivery signature serves as proof that the shipment was received by the intended recipient at the specified address.',

    APPTPU: 'Carrier will call ahead to set a date or time slot before collecting the goods.',
    APPTPUPS: 'Carrier must oblige to a specific date or time slot that the Shipper is imposing.',
    APPTDEL: 'Carrier will call ahead to set a date or time slot before delivering the goods.',
    APPTDELPS: 'Carrier must oblige to a specific date or time slot that the consignee is imposing.',
    BLIND: 'Blind shipments are commonly used in situations where businesses want to maintain anonymity between their suppliers and customers. ie: White Labeling: manufacturer produces products that are rebranded and sold by another company as their own. Dropshipping: manufacturer produces products that are rebranded and sold by another company as their own. The shipper might ask the manufacturer ship the products directly to the end customer without revealing the manufacturer identity.',
    INDEL: 'The service entails the driver unloading the pallet from the truck and moving it to an accessible location, such as a garage, using a pallet jack or similar equipment. The placement depends on the property\'s accessibility, but does not include entry into specific rooms or floors, installation, or further movement inside the building.',
    INPU: 'This service refers to a shipping and pickup service provided by carriers, where the carrier\'s personnel collect a shipment from a location that is inside a building, facility, or designated room, as opposed to a more easily accessible outdoor area.',
    LGDEL: 'A "Liftgate Delivery" refers to a delivery location that lacks the necessary equipment (like a loading dock or forklift) to handle the unloading process. An hydraulic liftgate is attached to a delivery truck or trailer to perform the unloading.',
    LGPU: 'A "Liftgate Pickup" refers to a pickup location that lacks the necessary equipment (like a loading dock or forklift) to handle the loading process. An hydraulic liftgate is attached to a delivery truck or trailer to perform the loading.',
    LTDDEL: '"Limited Access Delivery" refers to a delivery destination that presents challenges or restrictions to the transportation and delivery process due to its characteristics, location, or regulations. These destinations typically require special considerations, equipment, or procedures to ensure a successful delivery.',
    LTDPU: '"Limited Access Pickup" refers to a pickup location that presents challenges or restrictions to the transportation and pickup process due to its characteristics, location, or regulations. These locations typically require special considerations, equipment, or procedures to ensure a successful pickup.',
    RESDEL: 'A "Residential Delivery" refers to the service of delivering goods to a residential area. It is important to note that a Business can be located in a area defined as Residential by a carrier.',
    RESPU: 'A "Residential Pickup" refers to the service of collecting goods from a residential area. It is important to note that a Business can be located in a area defined as Residential by a carrier.',
    DOCKDEL: 'The consignee is equiped with a receiving dock. A dock provides a designated area for the efficient unloading of goods directly from a trailer.',
    DOCKPU: 'The shipper is equiped with a loading dock. A dock provides a designated area for the efficient loading of goods directly to a trailer.',
    INDIRSR: 'An "indirect signature" means that someone other than the recipient can sign for the package. This could be a family member, neighbor, or another person who can verify that the shipment was received at the correct address. This service is usually included for a delivery to a commerce. * see carriers T&C',
    COMDEL: 'A "Commercial Delivery" refers to the service of delivering goods to a commercial area. It is important to note that a Business can be located in a area defined as Residential by a carrier.',
    COMPU: 'A "Commercial Pickup" refers to the service of collecting goods from a commercial area. It is important to note that a Business can be located in a area defined as Residential by a carrier.',
    DCDEL: 'The consignee is a distribution center, which is a facility used for receiving, storing, and redistributing goods to various location.',
    PFH: 'Protect from heat description',
    PFZ: 'Protect from freezing description',
    HLDPU: 'Hold For Pickup description',
    CALLTAG: 'Call Tag description',
    GROPU: 'Grocery Warehouse Pickup description',
    GRODEL: 'Grocery Warehouse Delivery description',
    DRYICE: 'Dry Ice description',
    NONCONV: 'Non Conveyable description',
    SATDEL: 'Saturday Delivery description',
    SATPU: 'Saturday Pickup description',
    HAZM: 'Dangerous Goods description',
    BATTERY: 'Lithium Batteries description',
    OSNR: 'Origin Signature Not Required description',
    EXPDEC: 'Export Declaration description',
    EHS: 'Excessive Height description',
    ELS_10: 'Excessive Length, 10ft description',
    ELS_11: 'Excessive Length, 11ft description',
    ELS_12: 'Excessive Length, 12ft description',
    ELS_13: 'Excessive Length, 13ft description',
    ELS_14: 'Excessive Length, 14ft description',
    ELS_15: 'Excessive Length, 15ft description',
    ELS_16: 'Excessive Length, 16ft description',
    ELS_17: 'Excessive Length, 17ft description',
    ELS_18: 'Excessive Length, 18ft description',
    ELS_19: 'Excessive Length, 19ft description',
    ELS_20: 'Excessive Length, 20ft description',
    ELS_21: 'Excessive Length, 21ft description',
    ELS_22: 'Excessive Length, 22ft description',
    ELS_23: 'Excessive Length, 23ft description',
    ELS_24: 'Excessive Length, 24ft description',
    ELS_25: 'Excessive Length, 25ft description',
    ELS_26: 'Excessive Length, 26ft description',
    ELS_27: 'Excessive Length, 27ft description',
    ELS_28: 'Excessive Length, 28ft description',
    ELS_29: 'Excessive Length, 29ft description',
    ELS_30: 'Excessive Length, 30ft and over description',
    ELS_8: 'Excessive Length, 8ft description',
    ELS_9: 'Excessive Length, 9ft description',
}

export const SIGNATURE_ACCESSORIALS = ['ASR', 'DSNR', 'SR']
export const AVAILABLE_FREIGHT_ACCESSORIALS = ['APPTPU', 'APPTPUPS', 'APPTDEL', 'APPTDELPS', 'BLIND', 'INDEL', 'INPU', 'LGDEL', 'LGPU', 'LTDDEL', 'LTDPU', 'RESDEL', 'RESPU', 'PFZ', 'GROPU', 'GRODEL', 'PFH'/*, 'DOCKDEL', 'DOCKPU'*/]
export const AVAILABLE_MAIL_ACCESSORIALS = ['ASR', 'DSNR', 'SR', 'WEDEL', 'INDIRSR', 'RESDEL', 'RESPU', 'COMDEL', 'COMPU', 'HLDPU', 'CALLTAG']
export const RESIDENTIAL_ACCESSORIALS = ['RESDEL', 'RESPU']
// TODO: will need add 'Commercial Delivery' ('COMDEL), 'Commercial Pickup"('COMPU') accessorials in DB.
export const COMMERCIAL_ACCESSORIALS = ['COMDEL', 'COMPU']
export const FIXED_ACCESSORIALS = ['RESDEL', 'RESPU', 'COMDEL', 'COMPU']

export const filterAddressbookAccessorials = (
    accessorialList: SearchRfqAccessorial[],
    transportType: TransportType,
    addressType?: AddressType,
    addressBookAccessorials: AddressAccessorial[] = [],
) => {
    const addressbookAccessorialCodes = addressBookAccessorials.map((acc) => acc.accessorial.code) ?? []
    const filtered = accessorialList.filter((acc) => {

        // Keep only LOCATION accessorials with the right transportType
        if (!acc.accessorial.transportTypes.includes(transportType)) {

            return false
        }

        const isResidential = RESIDENTIAL_ACCESSORIALS.includes(acc.accessorial.code)
        const isCommercial = COMMERCIAL_ACCESSORIALS.includes(acc.accessorial.code)

        if (((addressType && addressType === AddressType.BUSINESS) || !addressType) && isResidential) {

            return false
        }
        else if (((addressType && addressType === AddressType.RESIDENTIAL) || !addressType) && isCommercial) {

            return true
        }

        return true
    })
        // Set them all selected
        .map((acc) => {
            acc.selected = addressbookAccessorialCodes.includes(acc.accessorial.code) || FIXED_ACCESSORIALS.includes(acc.accessorial.code)
            acc.isRemovable = !FIXED_ACCESSORIALS.includes(acc.accessorial.code)

            return acc
        })
        .sort((a, b) => {
            // non-removable first
            const isOneNonRemovable = !a.isRemovable || !b.isRemovable
            if (isOneNonRemovable) {
                return (+a.isRemovable) - (+b.isRemovable)
            } else {
                return a.accessorial.name.localeCompare(b.accessorial.name)
            }
        })

    return filtered
}

export const completeHandlingHeader = (handling: any, t, index) => {

    const summaryWeightUnit = handling?.weightUnit === "LB" ? 'lb' : 'kg'
    const summaryDimensionUnit = handling?.widthUnit === "IN" ? '"' : 'cm'

    return <>
        <b>{t('Unit').toString()} {handlingUnitLetters[index]}</b> - {handling?.packageType && t(handling?.packageType)} {(handling?.quantity)}x •&nbsp;
        {handling?.pieces} {t("pieces")} •&nbsp;
        {handling.length}{summaryDimensionUnit} {t('(L)')} x&nbsp;
        {handling?.width}{summaryDimensionUnit} {t('(W)')} x&nbsp;
        {handling?.height}{summaryDimensionUnit} {t('(H)')} •&nbsp;
        {handling?.totalWeight}{summaryWeightUnit} •&nbsp;
        {handling?.description} • {handling?.poNumber}<br />

    </>

}